.ant-form {
  .ant-form-item {
    .ant-form-item-label {
      padding: 0;
      label {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 5px;
        letter-spacing: 0.2px;
        color: #555770;
      }
    }
  }
}
