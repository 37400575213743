.ant-input {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  height: auto;
  padding: 7px 16px;
  background: #ffffff;
  border: 1px solid #c7c9d9;
  box-sizing: border-box;
  box-shadow: inset 0px 0.5px 4px rgba(96, 97, 112, 0.32);
  border-radius: 8px;
  color: #1c1c28;
  &::placeholder {
    color: #8f90a6;
  }
}
