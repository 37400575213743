.menu-page {
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
  &-section {
    margin: 10px 0;
  }
  .layout-content {
    height: calc(100vh - 64px);
    overflow: hidden;
    background: #f9f9f9;
    padding: 0px 5%;

    .my-classes {
      margin-bottom: 1px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.16),
        0px 0px 1px rgba(40, 41, 61, 0.04);
      padding: 20px 24px;
      .my-title {
        display: inline-flex;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        h3 {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 1.44px;
          width: calc(100% - 50px);
          color: #8f90a6;
        }
        .btn-add {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #3e7bfa;
          display: inline-flex;
          align-items: center;
          svg {
            font-size: 18px;
            margin-right: 5px;
          }
        }
      }
      .list-classes {
        .class-item {
          display: inline-block;
          width: 100%;
          margin-bottom: 25px;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          /* identical to box height */

          letter-spacing: 0.173333px;

          color: #28293d;
          span {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.257143px;

            color: #555770;
          }
          .dot {
            margin: 0 7px;
          }
        }
        .show-more {
          // font-family: Gordita;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height */

          letter-spacing: 0.257143px;
          text-decoration-line: underline;

          color: #28293d;
        }
      }
    }
  }
}
