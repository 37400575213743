.ant-modal-mask {
  background: none;
}
.ant-modal {
  background: #ffffff;
  box-shadow: 0px 20px 32px rgba(96, 97, 112, 0.24),
    0px 2px 8px rgba(40, 41, 61, 0.08);
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  .ant-modal-content {
    .ant-modal-header {
      padding: 20px 36px;
      border-bottom: 0.5px solid #E4E4EB;
      .ant-modal-title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2px;
        color: #28293d;
      }
    }
    .ant-modal-body {
      text-align: center;
      padding: 36px;
    }
  }
}
