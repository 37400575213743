.ant-btn {
  height: auto;
  padding: 7px 24px;
  text-align: center;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.ant-btn-primary {
  background: #3e7bfa;
}


@media (max-width: 767px){
  .ant-btn{
    font-size: 13px;
  }
}

@media(max-width: 480px) {
  .ant-btn{
    font-size: 12px;
  }
}