.ant-select {
  .ant-select-selector {
    box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.16),
      0px 0px 1px rgba(40, 41, 61, 0.04);
    margin: 0;
    border: none !important;
    height: auto !important;
    padding: 5px 16px !important;
    border-radius: 8px !important;
    width: 100%;
    min-height: 40px;
    .ant-select-selection-item {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #1c1c28;
      padding: 3px 0;
    }
  }
  .ant-select-selection-placeholder {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1c1c28;
    opacity: 0.8;
  }
}
