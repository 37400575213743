.ant-card {
  background: #ffffff;
  box-shadow: 0px 20px 32px rgba(96, 97, 112, 0.24),
    0px 2px 8px rgba(40, 41, 61, 0.08);
  border-radius: 8px;
  .ant-card-head {
    padding: 0 36px;
    border-bottom: 0.5px solid #e4e4eb;
    .ant-card-head-title {
      padding: 18px 0;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      color: #28293d;
    }
  }
  .ant-card-body {
    padding: 22px 36px;
  }
}


@media (max-width: 767px){
  .ant-card{
    .ant-card-head{
      padding: 0 24px;
      .ant-card-head-title{
        padding: 12px 0;
      }
    }
  }
}