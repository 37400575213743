.feed-ctn {
    height: calc(100vh - 64px);
    overflow: auto;
    overflow-x: hidden;
    padding: 24px 4px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    .hashtagContainer {
        background: #ffffff;
        padding: 16px;
        display: inline-flex;
        width: 100%;
        background: #ffffff;
        /* Light / Elevation 02 */
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .hashtag {
            display: flex;
            flex-direction: column;

            .hashtagCurrent {
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                line-height: 18px;
                /* identical to box height, or 120% */
                letter-spacing: -0.0107143px;
                color: #28293D;
            }

            .hashtagText {
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 18px;
                /* identical to box height, or 180% */
                letter-spacing: -0.0107143px;
                color: #8F90A6;
            }
        }

        .hashtagPostCount {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            /* identical to box height, or 138% */
            letter-spacing: -0.0107143px;
            color: #28293D;
        }
    }

    .list-activities {
        margin-top: 16px;
        // background: #ffffff;
        // box-shadow: 0px 0.5px 2px rgba(96, 97, 112, 0.16),
        //   0px 0px 1px rgba(40, 41, 61, 0.08);
        // border-radius: 8px;
    }
}