.ant-picker {
  border: none;
  background: #ffffff;
  /* Light / Elevation 06 */
  height: auto;
  padding: 9px 16px;
  // box-shadow: 0px 20px 32px rgba(96, 97, 112, 0.24),
  //   0px 2px 8px rgba(40, 41, 61, 0.08);
  box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.16), 0px 0px 1px rgba(40, 41, 61, 0.04);
  border-radius: 8px;
  width: 100%;
  .ant-picker-input {
    .input {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* Dark / Dark 0 */

      color: #1c1c28;
      &::placeholder {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* Dark / Dark 0 */

        color: #1c1c28;
      }
    }
  }
}
