.ant-radio-wrapper {
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  letter-spacing: 0.2px;

  color: #1c1c28;
  .ant-radio {
    .ant-radio-inner {
      border-color: #3e7bfa;
    }
  }
}
.ant-radio-wrapper-checked{
  .ant-radio-checked{
    .ant-radio-inner{
      background-color: #3E7BFA;
      &::after{
        background-color: #fff;
      }
    }
  }
}